import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20da4518"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fatherbox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavTop = _resolveComponent("NavTop")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.navShow)
      ? (_openBlock(), _createBlock(_component_NavTop, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createVNode(_component_router_view)
    ])
  ]))
}